
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/asiajye_promotion",
      function () {
        return require("private-next-pages/asiajye_promotion/index.page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/asiajye_promotion"])
      });
    }
  